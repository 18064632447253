"use client";

import classNames from "classnames/bind";
import { Kicker } from "../../kicker/kicker";
import { BlocksLifecycleTeaser } from "../../../__generated__/contentful-types";
import { getContentfulVideoUrl } from "../../../helpers/getContentfulVideoUrl";
import { helpers } from "@contentful/rich-text-types";
import { CosmosButton, CosmosIconChevronRight } from "@cosmos/web/react";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { notNullish } from "../../../helpers/notNullish";
import styles from "./lifecycle-teaser.module.css";

const cx = classNames.bind(styles);

export const LifecycleTeaser = ({
  block,
}: {
  block: BlocksLifecycleTeaser;
}) => {
  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("card")}>
          <div className={cx("content")}>
            {(block.kicker || block.title) && (
              <div className={cx("heading-group")}>
                {block.kicker && (
                  <Kicker
                    className={cx("kicker")}
                    text={block.kicker}
                    kind="underlined"
                  />
                )}
                {block.title && <h2 className={cx("title")}>{block.title}</h2>}
              </div>
            )}
            {block.body?.json.content.map((content, index) => (
              <p key={index} className={cx("text")}>
                {content.content
                  .filter(helpers.isText)
                  .map((_content) =>
                    _content.marks[0] &&
                    (_content.marks[0].type === "bold" ||
                      _content.marks[0].type === "italic") ? (
                      <span className={cx(`text--${_content.marks[0].type}`)}>
                        {_content.value}
                      </span>
                    ) : (
                      _content.value
                    ),
                  )}
              </p>
            ))}
            <div className={cx("button-wrapper")}>
              {block.quizLinkUrl &&
                block.quizLinkText &&
                block.linkUrl &&
                block.linkText && (
                  <CosmosButton
                    href={block.linkUrl}
                    size="large"
                    kind="tertiary"
                    className={cx("button")}
                  >
                    {block.linkText}
                  </CosmosButton>
                )}
              <CosmosButton
                href={(block.quizLinkUrl || block.linkUrl) ?? undefined}
                size="large"
                kind="link"
                appearance="dark"
                icon-placement="after"
                className={cx("button")}
              >
                <CosmosIconChevronRight
                  slot="icon"
                  className={cx("button-icon")}
                />
                {block.quizLinkText ? block.quizLinkText : block.linkText}
              </CosmosButton>
            </div>
          </div>
          {block.image && (
            <div className={cx("media-wrapper")}>
              {block.image.contentType === "video/mp4" ? (
                <video
                  className={cx("asset")}
                  preload="auto"
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  loop={true}
                  src={
                    block.image.url
                      ? getContentfulVideoUrl(block.image.url)
                      : undefined
                  }
                />
              ) : (
                <>
                  {block.image.url && (
                    <picture>
                      {(["avif", "webp"] as const).map((format, index) => (
                        <source
                          key={index}
                          type={`image/${format}`}
                          srcSet={
                            /* sizes matched to "sizes" attribute on <img> element: */ [
                              380,
                              400,
                              420,
                              600,
                              672,
                              block.image?.width,
                            ]
                              .filter(notNullish)
                              .flatMap((width) => [width, width * 2]) // <- Provide high-DPI (aka. "retina") variants
                              .filter(
                                (width) =>
                                  block.image?.width &&
                                  width <= block.image?.width, // <- Exclude widths larger than the original
                              )
                              .map((width) => {
                                const src = getContentfulImageUrl(
                                  block.image?.url ?? "",
                                  {
                                    fm: format,
                                    width,
                                  },
                                );

                                return `${src} ${width}w`;
                              })
                              .join(", ")
                          }
                        />
                      ))}
                      <img
                        loading={imageLoading}
                        src={block.image.url}
                        sizes="(min-width: 1000px) min(672px, 100vw), min(600px, 100vw)"
                        className={cx("asset")}
                        alt={block.image.description ?? ""}
                      />
                    </picture>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
